import React, { useEffect, useState } from "react";
import i18n from "i18n";
import { Box } from "@mui/system";
import { getACleanURI } from "helpers/utilities";
import DropMenu from "components/Menu/DropMenu";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import cockpitActions from "redux/actions/cockpitActions";
import { Tooltip } from "@material-ui/core";
import GetChannelImage from "helpers/GetChannelImage";
import lod_ from "lodash";
import { getMesssageItemSelector } from "redux/selectors/cockpitSelectors";
import { getSenderOfMessage } from "helpers/messageUtils";
import engageActions from "redux/actions/engageActions";
import FileActions, { mimeTypeCompute } from "helpers/actions/FileActions";
/**
 *
 * @param {*} param0
 * @returns
 */
export const MessageAttachmentsList = ({ message }) => {
	const [attachments, setAttachments] = useState([]);

	useEffect(() => {
		if (message?.sent_attachments) {
			if (Array.isArray(message.sent_attachments) && message.body.repliedAnswer) {
				setAttachments(message.sent_attachments);
			}
		}
		if (message?.payload?.payload?.attachments) {
			if (Array.isArray(message?.payload?.payload?.attachments)) {
				setAttachments(message?.payload?.payload?.attachments);
			}
		}
	}, [message]);

	return (
		<>
			{attachments?.length > 0 && (
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						flexWrap: "flexWrap"
					}}
					fontWeight="fontWeightMedium"
					mx={1}
					mb={0.5}
				>
					<p> {i18n.t("COC.Attachements")} :</p>
					<GenericAttachmentList arrayOfAttachments={attachments} mID={message.header.mID} />
				</Box>
			)}
		</>
	);
};

/**
 *
 */
export const GenericAttachmentList = ({ arrayOfAttachments, mID }) => {
	return arrayOfAttachments.map((attachment, i) => {
		let name = attachment.name || attachment.filename;
		let url = getACleanURI(attachment.url || attachment.file_url, "browser");
		return (
			<FileActions
				key={i}
				mID={mID}
				attachment={{
					// Default attachment attributes
					...attachment,
					// Need to recompute this attributes
					name: name,
					url: url,
					mimeType: mimeTypeCompute(attachment)
				}}
				customOpen={
					<Tooltip
						title={!lod_.isNil(name) && !lod_.isEmpty(name) && name.length > 50 ? name : ""}
						placement="top"
					>
						<p className="linkHover" rel="noopener noreferrer" target="_blank">
							{!lod_.isNil(name) && !lod_.isEmpty(name) && name.length > 50
								? name.slice(0, 50) + " ..."
								: name}
							{arrayOfAttachments?.length - 1 !== i && ","}
						</p>
					</Tooltip>
				}
			/>
		);
	});
};
