// conversation.header.direction
export const directions = Object.freeze({
	INBOUND: "Inbound", // A conversation might be initiated in an inbound way (Incoming message first)
	OUTBOUND: "Outbound" // Or in an outbound way (Outgoing message first, like engage)
});

// Differents actions possibly received from the conversation actions
export const CONVERSATION_ACTIONS_RESULT_ACTIONS = {
	BUILD_DRAFT: "buildDraft"
};
